import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import { Link } from "gatsby";

const TeamGrid = ({ team }) => {
  team.doctors.map((el) => {
    el.nameChunkOne = el.name.split("med.")[0] + " med.";
    el.nameChunkTwo = el.name.split("med.")[1];
    return el;
  });

  return (
    <div id="Praxisteam" class="px-20p sm:px-10 bg-footerBg py-50p sm:py-80p ">
      <div class="max-w-900p mx-auto">
        <div class="w-max">
          <h2 class="text-primary font-bold text-15152 sm:text-18182 mb-15p sm:mb-20p">
            {team.title}
          </h2>
          <div class="h-[0.175rem] bg-secondary rounded-full mb-35p sm:mb-60p" />
        </div>
        <div class="flex flex-col sm:flex-row items-center sm:justify-around space-y-35p sm:space-y-0 mb-35p sm:mb-60p">
          {team.doctors.map((el, index) => (
            <div className="bg-white p-30p">
              <div class="mx-auto w-60 pb-25p sm:pb-20p">
                <GatsbyImage
                  image={el.imageObject.image.childImageSharp.gatsbyImageData}
                  alt={el.imageObject.alt}
                />
              </div>

              <h3 class="text-12121 sm:text-15152 font-bold text-primary pb-15p sm:pb-20p">
                {el.nameChunkOne} <br /> {el.nameChunkTwo}
              </h3>
              <Link
                to={`/praxisteam#${index + 1}`}
                class="text-09697 sm:text-12121 font-medium text-lightblue"
              >
                {el.linkText}
              </Link>
            </div>
          ))}
        </div>
        <Link
          to="/praxisteam#team"
          class="text-12121 sm:text-15152 font-medium text-lightblue w-full text-center block"
        >
          {team.linkTextTeam}
        </Link>
      </div>
    </div>
  );
};

export default TeamGrid;
