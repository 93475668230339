import React from "react";

import Herosection from "../components/Herosection";
import News from "../components/News";
import Team from "../components/Team";
import Services from "../components/Services";
import Contact from "../components/Contact";

export const IndexPageTemplate = ({
  herosection,
  news,
  team,
  services,
  contact,
}) => {
  return (
    <React.Fragment>
      <Herosection herosection={herosection} contact={contact} />
      <News news={news} />
      <Team team={team} />
      <Services services={services} />
      <Contact contact={contact} herosection={herosection} />
    </React.Fragment>
  );
};
