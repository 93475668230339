import React from "react";

import { remark } from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";

const Services = ({ services }) => {
  if (services.content !== "") {
    const content = remark()
      .use(recommended)
      .use(remarkHtml)
      .processSync(services.content)
      .toString()
      .replace("<h1>", "<h3>")
      .replace("</h1>", "</h3>")
      .replace("<h1>", "<h3>")
      .replace("</h1>", "</h3>")
      .replace("<h1>", "<h3>")
      .replace("</h1>", "</h3>")
      .replace("<h1>", "<h3>")
      .replace("</h1>", "</h3>")
      .replace("<h1>", "<h3>")
      .replace("</h1>", "</h3>");

    //console.log(content);

    return (
      <div id="leistungen" class="px-20p sm:px-10 py-50p sm:py-80p">
        <div class="max-w-900p mx-auto">
          <div class="w-max">
            <h2 class="text-primary font-bold text-15152 sm:text-18182 mb-15p sm:mb-20p">
              {services.title}
            </h2>
            <div class="h-[0.175rem] bg-secondary rounded-full mb-35p sm:mb-60p" />
          </div>
          <div
            class="markdownLeistungen"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    );
  }
  return null;
};
export default Services;
